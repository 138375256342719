exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-blog-all-js": () => import("./../../../src/pages/blog/all.js" /* webpackChunkName: "component---src-pages-blog-all-js" */),
  "component---src-pages-blog-index-js": () => import("./../../../src/pages/blog/index.js" /* webpackChunkName: "component---src-pages-blog-index-js" */),
  "component---src-pages-blog-strapi-post-slug-js": () => import("./../../../src/pages/blog/{strapiPost.slug}.js" /* webpackChunkName: "component---src-pages-blog-strapi-post-slug-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-cv-js": () => import("./../../../src/pages/cv.js" /* webpackChunkName: "component---src-pages-cv-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-indexing-index-js": () => import("./../../../src/pages/indexing/index.js" /* webpackChunkName: "component---src-pages-indexing-index-js" */),
  "component---src-pages-projects-index-js": () => import("./../../../src/pages/projects/index.js" /* webpackChunkName: "component---src-pages-projects-index-js" */),
  "component---src-pages-projects-misc-index-js": () => import("./../../../src/pages/projects/misc/index.js" /* webpackChunkName: "component---src-pages-projects-misc-index-js" */),
  "component---src-pages-projects-strapi-project-slug-js": () => import("./../../../src/pages/projects/{strapiProject.slug}.js" /* webpackChunkName: "component---src-pages-projects-strapi-project-slug-js" */),
  "component---src-pages-projects-video-index-js": () => import("./../../../src/pages/projects/video/index.js" /* webpackChunkName: "component---src-pages-projects-video-index-js" */),
  "component---src-pages-projects-web-index-js": () => import("./../../../src/pages/projects/web/index.js" /* webpackChunkName: "component---src-pages-projects-web-index-js" */),
  "component---src-pages-research-index-js": () => import("./../../../src/pages/research/index.js" /* webpackChunkName: "component---src-pages-research-index-js" */),
  "component---src-pages-research-strapi-research-project-slug-js": () => import("./../../../src/pages/research/{strapiResearchProject.slug}.js" /* webpackChunkName: "component---src-pages-research-strapi-research-project-slug-js" */),
  "component---src-pages-teaching-courses-js": () => import("./../../../src/pages/teaching/courses.js" /* webpackChunkName: "component---src-pages-teaching-courses-js" */),
  "component---src-pages-teaching-examples-index-js": () => import("./../../../src/pages/teaching/examples/index.js" /* webpackChunkName: "component---src-pages-teaching-examples-index-js" */),
  "component---src-pages-teaching-examples-strapi-teaching-material-slug-js": () => import("./../../../src/pages/teaching/examples/{strapiTeachingMaterial.slug}.js" /* webpackChunkName: "component---src-pages-teaching-examples-strapi-teaching-material-slug-js" */),
  "component---src-pages-teaching-observations-js": () => import("./../../../src/pages/teaching/observations.js" /* webpackChunkName: "component---src-pages-teaching-observations-js" */),
  "component---src-pages-teaching-philosophy-js": () => import("./../../../src/pages/teaching/philosophy.js" /* webpackChunkName: "component---src-pages-teaching-philosophy-js" */)
}

